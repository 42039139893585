.page_content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 80vh;
    @include transition($transitionSidebar);
}

.page_border{
    min-height: 80vh;
    background-repeat: no-repeat;
    background-position: calc(100% + 345px) 0;
}

.project_page_border{
    min-height: 80vh;
    background-repeat: no-repeat;
    background-size: contain;
}

.opt_top-banner{
    margin-top: 120px;
    position: relative;
    padding-top: 15px;
    .opt_grid img {
      width: 90%;
    }
    @media(min-width: $tabletMin){
        margin-top: 150px;
        margin-bottom: 60px;
    }
    @media (min-width: $laptopMin){
        height: 100vh;
        // margin-bottom: -130px!important;
        margin-top: 0;
        min-height: 550px;
        .opt_grid img {
            width: 100%;
        }
    }
}

.opt_double-grid-banner{
    position: relative;
}

.opt_slider-section{
    
    h2{
        color: $themeTurquoise
    }
}

.opt_small-heading-section{
    color: $white;
    margin-top: 120px;
    @media (min-width: $tabletMin){
        .opt_grid{
            &:nth-of-type(2){
                text-align: right;
            }
        }
    }
    
    .opt_container{
        border-bottom: 1px solid $themeBlue;
    }
}

.opt_bg-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 440px;
}
@media (min-width: $desktopMin){
    .opt_bg-img{
        height: 768px;
        /*width: 668px;*/
        width: calc(50vw - 30px);
    }
}

.opt_featured-project{
    .opt_featured-project-grid{
        height: 610px;
    }

    .opt_featured-project-img{
        /*height: 530px;*/
        background-repeat: no-repeat;
        /*background-position: right;*/
        background-size: cover;
        position: relative;
        width: 100%;
        max-height: 530px;
        min-height: 416px;
    }

    .opt_featured-project-content{
        max-width: 470px;
        position: relative;
        bottom: -440px;
        left: 50%;
        transform: translateX(-50%);
        h2{
            text-transform: uppercase;
            font-size: 1.8rem;
            margin-bottom: 15px;
            @media(min-width: $laptopMin){
                font-size: 2.8rem;
            }
        }
        h3{
            margin: 15px 0 15px 0;
        }
        a{
            font-size: 1.3rem;
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }
    @media (min-width: $laptopMin) {
        .opt_featured-project-grid{
            height: 100%;
        }
        .opt_featured-project-grid:nth-of-type(odd){
            margin-bottom: 100px;
        }

        .opt_featured-project-grid:nth-of-type(even){
            margin-top: 100px;
        }
        .opt_featured-project-hover {
            .opt_featured-project-img {
                @include transition($transition);
                height: 430px;
                background-repeat: no-repeat;
                background-position: center;
                /*background-size: cover;*/
                position: relative;
                width: calc(370px - 50px);
                right: -50px;
            }

            .opt_featured-project-content {
                @include transition($transition);
                max-width: 320px;
                position: absolute;
                bottom: 32px;
                left: -50px;
                transform: none;
                h3{
                    margin: 15px 0 30px 0;
                }
                a{
                    font-size: 1.3rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
            h2, h3, a{
                @include transition($transition);
            }
            &:hover {
                .opt_featured-project-img {
                    width: 370px;
                    right: 0;
                }

                .opt_featured-project-content {
                    max-width: 370px;
                    position: absolute;
                    bottom: 32px;
                    left: 10px;
                    h3 {
                        margin: 15px 0 30px 0;
                    }
                    a {
                        font-size: 1.3rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        text-decoration: underline;
                    }
                }
                h2, h3{
                    color: #FFFFFF!important;
                }
                a{
                    color: $themeTurquoiseLight!important;
                }
            }
        }
    }
    @media (min-width: $laptopMin) {
        .opt_featured-project-hover {
            .opt_featured-project-img {
                @include transition($transition);
                height: 530px;
                background-repeat: no-repeat;
                background-position: center;
                /*background-size: cover;*/
                position: relative;
                width: calc(470px - 100px);
                right: -100px;
            }

            .opt_featured-project-content {
                @include transition($transition);
                max-width: 420px;
                position: absolute;
                bottom: 32px;
                left: -100px;
                transform: none;
                h3{
                    margin: 15px 0 30px 0;
                }
                a{
                    font-size: 1.3rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
            &:hover {
                .opt_featured-project-img {
                    width: 470px;
                    right: 0;
                }

                .opt_featured-project-content {
                    max-width: 470px;
                    position: absolute;
                    bottom: 32px;
                    left: 20px;
                    h3 {
                        margin: 15px 0 30px 0;
                    }
                    a {
                        font-size: 1.3rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .opt_featured-project-button{
        text-align: center;
        margin: 0 0 40px 0;
        @media(min-width: $laptopMin){
            margin: 50px 0 0 0;
        }
    }
}

.opt_what-we-do-section{
    background-color: $darkBlue;
    padding: 30px 0 15px 0;
    position: relative;
    background-repeat: no-repeat;
    background-position: calc(0% - 345px) 0;
    @media (min-width: $tabletMin){
        &.opt_double-grid-section{
            padding: 60px 0;
        }
        
    }
    ul{
        text-align: left;
        list-style: none;
        padding-left: 0;
        li{
            border-bottom: 2px solid transparent;
            @include transition(0.1s);
            a {
                font-size: 1.5rem;
                font-weight: 600;
                color: $themeTurquoise;
                width: 100%;
                line-height: 65px;
                display: block;
                position: relative;
                svg{
                    position: absolute;
                    right: 0;
                    bottom: 15px;
                }
                .opt_plus{
                    stroke: $themeTurquoise;
                    stroke-width: 1px;
                    @include transition($transition);
                }
            }
            @media (min-width: $laptopMin){
                a {
                    font-size: 2rem;
                }
            }
        }
        li:hover{
            border-bottom: 2px solid $themeTurquoise;
             a{
                color: $themeTurquoiseLight;
                .opt_plus{
                    stroke: $themeTurquoiseLight;
                    -ms-transform: rotate(-45deg);
                    -ms-transform-origin: center;
                    transform: rotate(-45deg);
                    transform-origin: center;
                    @include transition($transition);
                }
            }
        }
    }
}

.opt_who-we-are-section{
    /*max-height: 768px;*/
    position: relative;
    padding: 50px 0 20px 0;
    .opt_container{
        .opt_row {
            margin: 0;
        }
        /*padding:  0 0 0 20px;*/
        padding: 0;
        // .opt_grid-6:nth-of-type(2n) {
        //     padding: 30px 15px 15px 15px;
        // }
        .opt_grid-6:nth-of-type(2n+1) {
            padding: 0;
            margin: 0;
        }
    }
    .opt_who-we-are-list{
        color: $white;
        font-weight: 200;
        line-height: 25px;
        display: block;
        font-size: 1rem;
        letter-spacing: 0.0133rem;
        margin-bottom: 30px;
        span{
            font-size: 2.2rem;
            font-weight: 600;
            color: $themeTurquoise;
            margin: 0;
            padding-right: 0;
        }
    }
    @media (min-width: $tabletMin){
        &.opt_double-grid-section{
            padding: 60px 0;
        }
        
    }
    @media (min-width: $tabletMax){
        .opt_who-we-are-list{
            color: $white;
            font-weight: 200;
            line-height: 25px;
            display: inline-flex;
            span{
                font-size: 2.4rem;
                font-weight: 600;
                color: $themeTurquoise;
                margin: 17px 0 50px 0;
                padding-right: 33px;
            }
        }
    }
    @media (min-width: $laptopMin){
        &.opt_double-grid-section{
            padding: 0;
        }
        
        .opt_container{
            padding:  0 0 0 20px;
            .opt_grid-6:nth-of-type(2n){
                padding-right: 15px;
            }
            .opt_grid-6:nth-of-type(2n+1) {
                padding: 0 0 0 15px;
                margin-right: -15px;
            }
        }
        .opt_who-we-are-list{
            span{
                font-size: 3.8rem;
            }
        }
    }
    @media (min-width: $desktopMin){
        .opt_who-we-are-list{
            span{
                font-size: 4rem;
            }
        }
    }
}

.opt_clients-section{
    background-color: $darkBlue;
    padding: 30px 0 40px 0;
    @media (min-width: $tabletMin){
        &.opt_double-grid-section{
            padding: 60px 0;
        }
        
    }
    .opt_container {
        text-align: center;
    }
    .opt_client-img{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -5px;
        figure{
            padding: 5px 7px;
            width: 50%;
            margin: 0;
            img{
                width: 100%;
            }
            @media (min-width: $tabletMin){
                width: 33.3333%;
            }
            @media (min-width: $laptopMin){
                width: 20%;
            }
            @media (min-width: $desktopMin){
                width: 16.666%;
            }
        }
        
    }
}

.opt_about-people-section{
    /*max-height: 768px;*/
    position: relative;
    padding: 30px 0;
    .opt_container{
        padding:  0 0 0 20px;
        .opt_row {
            margin: 0;
        }
        /*padding:  0 0 0 20px;*/
        padding: 0;
        .opt_grid-6:nth-of-type(2n+1) {
            padding: 0;
        }
        .opt_grid-6:nth-of-type(2n) {
            padding: 15px;
            margin: 0;
        }
    }
    ul{
        margin-top: 50px;
        li{
            color: $white;
            font-weight: 200;
            line-height: 25px;
            display: inline-flex;
            span{
                font-size: 2.2rem;
                font-weight: 600;
                color: $themeTurquoise;
                margin: 17px 0 50px 0;
                padding-right: 33px;
            }
        }
    }
    @media(min-width: $tabletMin) {
        &.opt_double-grid-section{
            padding: 0;
        }
        
    }
    @media (min-width: $laptopMin){
        .opt_container{
            padding:  0 15px 0 15px;
            .opt_grid-6:nth-of-type(2n+1) {
                padding: 0 15px 0 0;
                margin-left: 0;
            }
            .opt_grid-6:nth-of-type(2n){
                padding-left: 15px;
            }
        }
        ul{
            li{
                span{
                    font-size: 3.8rem;
                }
            }
        }
    }
    @media (min-width: $desktopMin){
        .opt_bg-img{
            position: relative;
            left: calc(590px - 50vw);
        }
    }
}

.opt_contact-us-section{
    text-align: center;
    padding-bottom: 125px;
    margin-top: -155px;
    @media (min-width: $tabletMin){
        text-align: left;
    }
    span{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .img{
        margin-bottom: 20px;
    }
    .item{
        color: $white;
        font-weight: 200;
        line-height: 22px;
        margin: 30px 0;
        img{
            margin-right:20px;
        }
        h4{
            line-height: 40px;
        }
        a{
            color: $white;
            display: inline-flex;
        }
        .opt_contact-item {
            margin: 30px 0 30px 0;
        }
        .opt_social-item {
            margin: 23px 0 10px 0;
        }
    }
}

.opt_what-we-do-center{
    .opt_container{
        text-align: center;
        .opt_row{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

.opt_what-we-do-blocks{
    padding-bottom: 30px;
    .opt_grid {
        margin: 15px 0;
    }
    .opt_content{
        text-align: center;
        border:1px solid $themeTurquoise;
        padding: 15px;
        height: 100%;
        h4{
            font-size: 1.2rem;
        }
        img{
            width: 65px;
            margin-top: 20px;
        }
    }
}

.opt_who-we-are-blocks{
    padding-bottom: 30px;
    .opt_content{
        display: block;
        h4{
            margin-right: 0;
            font-size: 2.8rem;
        }
        
        @media (min-width: $tabletMin){
            display: inline-flex;
            h4{
                margin-right: 30px;
                margin-top: 0;
            }
        }
    }
}

.opt_who-we-are-gallery{
    margin-bottom: 30px;
    @media (min-width: $tabletMin){
        padding: 30px 0;
        margin-bottom: 90px;
    }
    @media (min-width: $laptopMin){
        margin-bottom: 130px;
    }
    .white{
        margin-bottom: 30px;
    }
    .opt_who-we-are-row{
        position: relative;
        figure {
            margin: 0 auto;
            max-width: 324px;
        }
        .opt_grid {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .oo_name{
            color: $white;
            margin-bottom: 6px;
        }
        .oo_position{
            color: $white;
            font-weight: 400;
            margin-top: 0;
        }

        @media (min-width: $tabletMin){
            .oo_item:nth-child(3n+3){
                position: relative;
                top: -160px;
            }
            .oo_item:nth-child(3n+2){
                position: relative;
                /*top: -110px;*/
            }
            .oo_item:nth-child(3n+1){
                position: relative;
                top: 60px;
            }
            .oo_overlay {
                position: absolute;
                left: 15px;
                top: 15px;
                // width: calc(100% - 30px);
                // height: calc(100% - 30px);
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7329132336528361) 100%);
                visibility: hidden;
                opacity: 0;
                @include transition(0.25s);
            }
            .oo_info{
                position: absolute;
                bottom: 0;
                left: -15px;
                width: calc(100% - 30px);
                padding: 15px;
                visibility: hidden;
                opacity: 0;
                @include transition(0.35s);
            }
            .oo_name{
                margin-bottom: 0;
                @include transition(0.25s);
                line-height: 44px;
            }
            .oo_position{
                margin-top: 0;
            }
            .oo_item{
                cursor: pointer;
                &:hover{
                    .oo_overlay{
                        visibility: visible;
                        opacity: 1;
                    }
                    .oo_info{
                        visibility: visible;
                        opacity: 1;
                        left: 15px;
                        background: rgb(0,0,0);
                        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                        bottom: 21px;
                        .oo_name{
                            &:hover{
                                color: $themeTurquoise;
                            }
                        }
                        @media(min-width: $desktopMin){
                            left: 32px;
                            width: calc(100% - 65px);
                        }
                    }
                    
                }
            }
        }
        @media (min-width: $laptopMin){
            .oo_item:nth-child(3n+3){
            position: relative;
            top: -110px;
            }
            .oo_item:nth-child(3n+2){
                position: relative;
                /*top: -110px;*/
            }
            .oo_item:nth-child(3n+1){
                position: relative;
                top: 110px;
            }
        }
    }
}

.opt_project-page-heading{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1{
        color: $white;
    }
    .opt_container{
        padding: 130px 20px 30px 20px
    }
    @media(min-width:$tabletMin){
        .opt_container{
            padding: 150px 20px 60px 20px
        }
    }
    @media(min-width:$laptopMin){
        .opt_container{
            padding: 200px 20px 80px 20px
        }
    }
    
    .opt_grid-4{
        position: relative;
        img{
            width: 170px;
        }
        @media (min-width: $tabletMin){
            img{
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                max-height: 100px;
                width: auto;
                max-width: 100%;
            }
        }
        
    }
    .opt_project-page-overlay{
        height: 100%;
    }
}

.opt_project-page-center{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .opt_container{
        padding: 15px 20px;
        // text-align: center;
        .opt_row{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    @media (min-width: $tabletMin) {
        .opt_container{
            padding: 30px 20px;
        }
    }
    @media (min-width: $laptopMin) {
        padding: 30px 0;
        .opt_container{
            padding: 0 20px;
        }
    }
}

.opt_project-page-grid{
    .opt_content{
        h2{
            color: $white;
        }
    }
    .opt_featured-project-content{
        h2{
            color: $white;
        }
        a{
            color: $white;
        }
    }
    .opt_featured-project-button{
        padding: 60px 15px 15px 15px;
        @media (min-width: $tabletMin) {
            padding: 15px;
        }
    }
}

.opt_thank-you-section{
    height: 100vh;
    .opt_container{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        margin: 0;
    }
}

.opt_home-featured-projects{
    margin-bottom: 60px;
}
.opt_top-heading.opt_home-featured-projects{
    margin-top: 30px;
}

.opt_all-about-the-people-img{
    height: 0;
    padding-bottom: 80%;
    @media(min-width: $laptopMin){
        padding-bottom: 93%;
    }
    @media(min-width: $desktopMin){
        padding-bottom: 105%;
    }
}

.opt_who-we-are-img{
    padding-bottom: 91%;
    height: 0;
    @media(min-width: $laptopMin){
        padding-bottom: 110%;
    }
}

.opt_online-brand-dev{
    padding-bottom: 30px;
    @media(min-width: $laptopMin){
        &.opt_double-grid-section{
            padding-top: 0;
        }
    }
}

.opt_web-dev-section{
    padding-bottom: 30px;
}

.opt_digital-des-section{
    padding: 15px 0;
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
}

.opt_ecommerce-section{
    padding: 15px 0;
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
}

.opt_ui-ux-section{
    padding: 15px 0;
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
}

.opt_crm-section{
    padding: 15px 0;
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
}

.opt_paid-search-section{
    padding: 15px 0;
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
    @media(min-width: $laptopMin){
        padding: 30px 0 60px 0;
    }
}

.opt_featured-project {
    @media(min-width: $tabletMin){
        padding-bottom: 30px;
    }
    @media(min-width: $laptopMin){
        &.opt_double-grid-section{
            padding-bottom: 60px;
        }        
    }
}

.opt_email-marketing{
    @media(min-width: $tabletMin){
        padding: 30px 0;
    }
}

.opt_project-page-overlay{
    margin-top: -17px;
    @media(min-width: $laptopMin){
        margin-top: 0;
    }
}
.opt_project-page-center{
    margin-top: -17px;
    @media(min-width: $laptopMin){
        margin-top: 0;
    }
}

.opt_who-we-are-section{
    .opt_grid-6:nth-of-type(2n){
        @media(min-width: $laptopMin){
            padding-left: 0;
        }
    }
}

._privacy-policy{
    color: #fff;
    h3{
        font-weight: 600;
        margin-top: 40px;
    }
    ._panel{
        max-width: 800px;
        margin: 0 auto;
    }
}