// colors
$themeBlue: #141428;
$themeBlueOverlay: rgba(20, 20, 40, 0.7);
$themeTurquoise: #1BC0AF;
$themeTurquoiseLight: #00FFE5;
$themeGrey: #38384B;
$darkBlue: #0A0A1E;
$white: #FFFFFF;
$overlay: rgba(0, 0, 0, 0.5);
$overlayLight:rgba(255, 255, 255, 0.5);
$alertRed: #D12E2E;

//scale
$sidebarWidth: 100%;
$sidebarHeight: 100%;
$containerPadding: 30px;
$SectionBottomMargin: 60px;

//transitions
$transitionSidebar: 0.7s ease-in-out;
$transition: 0.45s ease-in-out;

//media queries
$tabletMin: 700px;
$tabletMax: 699px;
$laptopMin: 1024px;
$laptopMax: 1023px;
$desktopMin: 1200px;
$desktopMax: 1199px;

//font-types
$themeFont: 'Titillium Web', sans-serif;
//font-size
$fontSmall: 0.8rem;