@mixin transition($speed){
    -webkit-transition: $speed;
    transition: $speed;
    -webkit-transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
    transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
}

@mixin positionCenter{
    position: absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
}
    