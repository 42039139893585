.opt_sidebar{
    position: fixed;
    left: 0;
    top:-$sidebarHeight;
    width: 100vw;
    height: $sidebarHeight;
    background-color: $themeBlue;
    @include transition($transitionSidebar);
    z-index: 1000;
    overflow-y: auto;
    color: $white;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: calc(100% + 350px) 50%;
    .opt_btn-menu{
        position: absolute;
        top: 0;
        padding: 33px 33px;
        background-color: transparent;
        border: none;
        color: $white;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 700;
        z-index: 1;
        @include transition($transition);
        img{
            width: 22px;
            height: 22px;
        }
    }

    ul{
        li{
            display: flex;
            justify-content: flex-start;
        }
        h6 {
            color: $themeTurquoise;
            transform: rotate(270deg);
            position: absolute;
            top: 6%;
            left: 0%;
        }
        @media (min-width: $tabletMin){
            h6 {
                top: 9%;
                left: 0%;
            }
        }
        @media (min-width: $desktopMin){
            h6 {
                left: -4%;
            }
        }
    }
    .opt_side_bar_discuss_project{
        position: relative;
        padding: 20px 45px;
        span{
            font-size: 1.1rem;
        }
        .vl {
            border-bottom: 1px solid $themeGrey;
            width: 35px;
            height:  0px;
            display: inline-block;
            margin: 0px 10px 3px 10px;
        }
    }
    @media (min-width: $tabletMin){
        .opt_side_bar_discuss_project{
            position: absolute;
            bottom: 0;
            right: 0;
            span{
                font-size: 1.1rem;
            }
            .vl {
                border-bottom: none;
                border-right: 1px solid $themeGrey;
                height: 80px;
                width: 0px;
                display: inline-block;
                margin: 30px 10px -30px 10px;
            }
        }
    }
    @media (min-width: $laptopMin){
        .opt_btn-menu{
            padding: 50px 50px;
        }
        .opt_side_bar_discuss_project{
            position: absolute;
            bottom: 0;
            right: 0;
            span{
                font-size: 1.5rem;
            }
                .vl {
                border-bottom: none;
                border-right: 1px solid $themeGrey;
                height: 80px;
                display: inline-block;
                margin: 30px 10px -30px 10px;
            }
        }
    }
    
}

.open {
    .opt_sidebar {
        top: 0;
    }
}

.opt_ul_big{
    /*position: absolute;
    top: 50%;
    transform:translateY(-50%);
    -webkit-transform:translateY(-50%);*/
    padding-left: 0;
    .opt_list-item_big{    
        font-size: 3rem;
        font-weight: 600;
        padding: 0;
        
        a{
            font-size: 2rem;
            color: $themeTurquoise;
            font-weight: 700;
            padding: 10px 45px;
            width: 100%;
            line-height: 40px;
        }

        a:hover{
            color: $themeTurquoiseLight;
        }

        @media (min-width: $tabletMin){
            font-size: 2.7rem;
            a{
                font-size: 2.7rem;
                /*padding: 10px 160px;*/
                line-height: 70px;
            }
        }
        @media (min-width: $laptopMin){
            font-size: 4rem;
            a{
                font-size: 4rem;
                line-height: 90px;
            }
        }
    }
}


.opt_list-item_flex{
    padding: 12px 60px;
    text-align: center;
    a{
        margin-right: 10px;
    }
    @media (min-width: $tabletMin){
        padding: 12px 100px;
    }
}