//button
.opt_button_turquoise{
    padding: 12px 0;
    border-radius: 60px;
    border:1px solid $themeTurquoiseLight;
    background: $themeTurquoise;
    color: $white;
    @include transition(0.15s);

    a{
        color: $white;
        padding: 12px 30px;
        text-transform: uppercase;
        font-size: 0.8rem
    }

    @media (min-width: $laptopMin){
        a{
            font-size: 1rem
        }
    }

    &:hover{
        background: $white;
        a{
            color: $themeTurquoise;
        }
    }
}

.opt_button_submit{
    padding: 12px 0;
    border-radius: 60px;
    border:1px solid $themeTurquoiseLight;
    background: $themeTurquoise;
    color: $white;
    @include transition(0.25s);

    a{
        color: $white;
        padding: 12px 30px;
        text-transform: uppercase;
        font-size: 0.8rem
    }

    @media (min-width: $laptopMin){
        a{
            font-size: 1rem
        }
    }

    &:hover{
        background: $white;
        a{
            color: $themeTurquoise;
        }
    }
}

.opt_button_close{
    padding: 12px 30px;
    border-radius: 60px;
    border:1px solid $themeTurquoiseLight;
    background: $themeTurquoise;
    color: $white;
    text-transform: uppercase;
    font-size: 0.8rem;
    @include transition(0.25s);

    @media (min-width: $laptopMin){
        font-size: 1rem
    }

    &:hover{
        color: $themeTurquoise;
        background: $white;
    }
}

button
.opt_button_blue {
    padding: 12px 0;
    border-radius: 0;
    border:1px solid $themeTurquoise;
    background: $themeBlue;
    color: $white;
    @include transition(0.25s);

    a{
        color: $white;
        padding: 12px 20px;
        text-transform: uppercase;
    }

    &:hover{
        background: transparent;
    }
}

.opt_button_delete{
    background-color: $themeBlue;
    border: none;
    padding: 12px 20px;
    color: $alertRed;
}

.opt_button-addtocart{
    padding: 12px 20px;
    text-transform: uppercase;
}

.clearfix{
    clear:both;
    margin: 0;
    padding: 0;
    line-height: 0;
}

.opt_textAlign-center{
    text-align: center;
}

.opt_display-flex{
    display: flex;
    align-items: baseline;
}

.opt_flex-end{
    @media (min-width: $tabletMin) {
        display:flex;
        justify-content: flex-end;
    }
}

.textAlign-right{
    @media (min-width: $tabletMin) {
        text-align: right;
    }
}

.opt_justifyContent-spaceBetween{
    justify-content: space-between;
}
.opt_justifyContent-center{
    justify-content: center;
}

.opt_conten{

}

.opt_top-banner-text{
    height:100%;

    @media (min-width: $tabletMin){
        position: relative;
    }    
    
    .opt_content{

        @media (min-width: $tabletMin){
            position: absolute;
            top: 50%;
            left:0;
            transform: translateY(-50%);
        }
    }
}

.opt_banner-text{
    height:100%;

    @media (min-width: $laptopMin){
        position: relative;
    }    
    
    .opt_content{

        @media (min-width: $laptopMin){
            position: absolute;
            top: 50%;
            left:0;
            transform: translateY(-50%);
        }
    }
}

.opt_swopped{
    .opt_grid{
         &:nth-of-type(1){
            @media (min-width: $tabletMin){
                -webkit-order: 2;
                order: 2;
            } 
        }   
        &:nth-of-type(2){
            @media (min-width: $tabletMin){
                -webkit-order: 1;
                order: 1;
            } 
        }     
    }       
}

.opt_top-heading{
    margin-top: 60px;
    h1{
        color: $white;
    }
}

.opt_page-heading{
    padding: 130px 0 30px 0;
    @media(min-width:$tabletMin){
        padding: 150px 0 60px 0;
        h2{
            margin-top: 30px;
        }
    }
    @media(min-width: $laptopMin){
        padding: 190px 0 70px 0;
    }
}

.opt_popup-container{
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
    text-align: center;
    background-color: $themeBlue;
    .opt_popup-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1{
            font-size: 3.8rem;
            margin: 5px 0;
        }
        p{
            font-size: 1rem;
            margin: 5px 0;
        }
    }
}

.opt_scroll{
    .white{
        
        // ul{
        //     padding-left:15px;
        // }
        @media (min-width: $tabletMin){
            overflow-y: scroll;
            max-height: 170px;
            height: 100%;
            scrollbar-color: $themeTurquoise transparent;
            scrollbar-width: thin;
        }
        @media (min-width: $laptopMin){
            overflow-y: auto;
            max-height: 260px;
            padding-right: 10px;
        }
        @media (min-width: $desktopMin){
            overflow-y: auto;
            max-height: 300px;
        }
    }    
}

.opt_white-space{
    height: 120px;
    background-color: $darkBlue;
}

.opt_double-grid-section{
    @media (min-width: $tabletMin){
        padding: 30px 0;
    } 
    // @media (min-width: $laptopMin){
    //     padding: 60px 0;
    // }    
}

.opt_see-more-heading{
    @media(min-width: $tabletMin){
        margin-bottom: 0;
    }
}

.opt_project-page-heading{
    @media(min-width: $laptopMin){
        height: 100vh;
        position: relative;
        .opt_container {
            padding: 0 20px;
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.opt_wds{
    display:none;
    @media(min-width: $tabletMin){
        display: block;
    }
}

.opt_mobile{
    display:block;
    @media(min-width: $tabletMin){
        display: none;
    }
}

.opti_sm-icon-mobile{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.opt_twitter-icon{
    display:none;
    @media(min-width: $laptopMin){
        display: block;
    }
}

.opt_grid .opt_submitting-msg {
    margin-top: 20px;
    img{
        width: 40px;
    }
    p{
        margin: 0;
    }
}

.opt_recapcha{
    div{
        margin: 0 auto;
    }
}


footer .item .opt_socmed-1{
    img{
        margin-left: 0;
    }
}

@media(max-width: $tabletMin){
    .opt_text-left-aligned-on-mobile{
        .opt_container{        
            .white{
                text-align: left;
            }
        }
    }
}

.opt_dropshadow{
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.3);
}

._cookie{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $themeBlueOverlay;
    border-top: 1px solid $themeTurquoise;
    z-index: 1;
    color: #fff;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    a{
        color: $themeTurquoise;
    }
    ._button{
        cursor: pointer;
        width: 100px;
        padding: 12px 0;
        border-radius: 100px;
        background-color: $themeTurquoise;
        border: 1px solid $themeTurquoiseLight;
        color: #fff;
        text-transform: uppercase;
        font-size: 1rem;
    }
}