body{
    overflow-x: hidden;
}
.opt_app {
    position: relative;
}

.white{
    color: $white;
    a{
        color: $themeTurquoiseLight;
    }
    li{
        font-weight: 200;
    }
}

.themeTurquoise{
    color: $themeTurquoise
}