footer{
    background-color:$themeBlue;
    padding: $containerPadding;
    text-align: center;
    @media (min-width: $tabletMin){
        text-align: left;
    }
    span{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .img{
        margin-bottom: 20px;
    }
    .item{
        color: $white;
        font-weight: 200;
        line-height: 22px;
        margin: 30px 0;
        img{
            margin: 0 10px;
        }
        h4{
            line-height: 40px;
        }
        a{
            color: $white;
        }
        /*.opt_border-bottom {
            border-bottom: 2px solid  $white;
            width: 60px;
            margin: 30px 0px 30px 0px;
        }*/
        .opt_contact-item {
            margin: 30px 0 30px 0;
        }
        .opt_social-item {
            margin: 23px 0 10px 0;
        }
    }
    .opt_social-mobile {
        margin: 60px 0 0 0;
    }
    .opt_up-button{
        position: absolute;
        right: 20px;
        bottom: 30px;
    }
    .opt_display-mobile {
        display: none;
    }
    @media (min-width: $tabletMin){
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 50%;
            line-height: 25px;
            h4{
                line-height: 45px;
            }
            .opt_border-bottom {
                border-bottom: 2px solid  $white;
                width: 60px;
                margin: 45px 0px 45px 0px;
            }
            .opt_contact-item {
                margin: 23px 0 23px 0;
            }
        }
        .opt_social-mobile {
            margin: 30px 0;
        }
        .opt_up-button {
            position: absolute;
            right: 50px;
        }
        .opt_display-mobile {
            display: block;
        }
    }
    @media (min-width: $laptopMin){
        .item{
            width: auto;
            h4{
                line-height: 60px;
            }
            .opt_border-bottom {
                border-bottom: 2px solid  $white;
                width: 60px;
                margin: 38px 0px 38px 0px;
            }
            .opt_contact-item{
                margin: 23px 0 30px 0;
            }
        }
        justify-content: space-between;
        .opt_up-button{
            position: absolute;
            right: 50px;
            bottom: 60px;
        }
    }
}
@media (min-width: $tabletMin){
    footer{
        padding: 30px 20px 0 20px;
    }
}

.opt_footer-form{
    border: 1px solid $themeTurquoise;
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    width: 100%;
    .field{
        width: 100%;
        margin: 0!important;
    }
    button{
        background-color: $themeTurquoise!important;
        color: $darkBlue!important;
        margin: 0!important;
        border-radius: 0!important;
        border: 1px solid $themeTurquoise;
    }
    input{
        height: 100%;
        background: transparent!important;
        color: $white!important;
        border: none;
        width: 100%;
        padding: 8px;
        &:focus{
            border: none!important;
        }
    }
}

.footer_top{
    padding: 30px 20px 20px 20px;
    background-color: $darkBlue;
    @media(min-width: $tabletMin){
        padding: 60px 20px 40px 20px;
    }
    .opt_container {
        text-align: center;
         h2{
            color: $themeTurquoise;
        }
        h3{
            color: $white;
        }
    }
}