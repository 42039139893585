//Section

.opt_top-banner{
    .opt_container {
        @media (min-width: $laptopMin){
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
        }        
    }
}


//Container
.opt_container{
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1180px;
    text-align: center;
    @media (min-width: $tabletMin){
        text-align: left;
    }
    &.valign-center_halign-left{       
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        // min-height: 100vh;        
    }

    &.opt_small{
        max-width: 968px;
    }
}

.opt_center{
    text-align: center;
}

//Row
.opt_row{
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: $tabletMin){
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }    
}

//Grid
.opt_grid{
    padding: 0 15px;
    img{
        width: 100%;
    }
}

.opt_grid-12{
    width: 100%;
}
.opt_grid-9{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 75%;
        &:nth-of-type(odd){
            padding-right: 15px;
        }
        &:nth-of-type(even){
            padding-left: 15px;
        }
    }
}
.opt_grid-8{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 66.66%;
    }
}
.opt_grid-6{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 50%;
        &:nth-of-type(odd){
            padding-right: 15px;
        }
        &:nth-of-type(even){
            padding-left: 15px;
        }
    }
}
.opt_grid-4{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 33.33%;
    }
}
.opt_grid-3{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 25%;
    }
}
.opt_grid-2{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 16.66%;
    }
}
.opt_grid-1-5{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 20%;
    }
}
.opt_grid-2-5{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 40%;
    }
}
.opt_grid-3-5{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 60%;
    }
}
.opt_grid-4-5{
    width: 100%;
    @media (min-width: $tabletMin){
        width: 80%;
    }
}