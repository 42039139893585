header{
    position: fixed;
    height: 70px;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    @include transition($transition);

    @media (min-width: $tabletMin){
        height: 95px;
    }

    button{
        @include transition($transition);
        cursor:pointer;
    }

    img{
        @include transition($transition);
    } 
}

.opt_btn-menu {    
    right: 0;
    span{
        display: none;
        @media (min-width: $laptopMin){
            display: inline-block;
        }
    }
}

.opt_header-top-left-column{
    position: absolute;
	top: 0;
	left: 0;
    display: flex;
    padding: 33px 20px;
    @include transition($transition);

    img{
        width: 170px;
    }
    @media (min-width: $desktopMin){
        padding: 33px 60px;
        img {
             width: 190px;
         }
    }
    
}

.opt_transformed{
    .opt_logo-top{
        display: none;
    }
    .opt_logo-scrolled{
        display: block;
    }
} 

.opt_logo-scrolled{
    display: none;
}

.opt_header-top-right-column {
	position: absolute;
	top: 0;
	right: 0;
    display: flex;
    padding: 33px 20px;
    color: $white;
    @include transition($transition);
    button {
        margin: 0 10px 0 25px;
        background: transparent;
        border: none;
        padding: 0;
        position: relative;
        a{
            color: $white;
            display: flex;
            align-items: center;
        }
        img{
            width:30px;
            height: 30px;
        }

        @media (min-width: $tabletMin){
            margin: 0 10px 0 25px;
        }
    }
    .opt_discuss-project-button{
        background-color: $themeTurquoise;
        border:1px solid $themeTurquoiseLight;
        border-radius: 26px;
        a{
            @include transition($transition);
            img{
                width:48px;
                height: 48px;
            }
            span{
                display: none;
                color: transparent;
            }
            &:hover{
                @include transition($transition);
                span {
                    display: inline-block;
                    text-transform: uppercase;
                    color: $white;
                    padding-left: 20px;
                    font-weight: 500;
                    position: relative;
                    bottom: -2px;
                }
            }
        }
    }
    @media (min-width: $desktopMin){
        padding: 33px 60px;
    }
}

.opt_transformed{
    background-color: $themeBlue;
    height: 68px;
    
    @media (min-width: $tabletMin){
        height: 76px;
    }
    
    .opt_header-top-left-column {
        padding: 10px 20px;
        @media (min-width: $laptopMin){
             padding: 10px 40px;
        } 
        img{
            width: 145px;
            @media (min-width: $tabletMin){
                width: 170px;
            }            
        }
    }
    .opt_header-top-right-column {
        padding: 10px 10px;
        @media (min-width: $tabletMin) {
            padding: 15px 10px;
        }
        @media (min-width: $laptopMin){
            padding: 15px 40px;
        }
        button {
            margin: 0 10px 0 25px;
        }
    }
    .opt_discuss-project-button {
        border-radius: 24px;
        a{
            img{
                width: 45px;
                height: 45px;
            }
        }
    }
}