.opt_form-section{
    color: $white;
    // margin-top: 70px;
    .opt_grid{
        padding: 7px;
    }
    .opt_grid-6:nth-of-type(2n+1){
        padding: 7px;
    }
    .opt_grid-6:nth-of-type(2n){
        padding: 7px;
    }
    form{
        margin: 30px auto;
        max-width: 600px;
    }
    textarea{
        height: 124px;
        resize: none;
    }
}

.opt_form-controll{
    background-color: $themeBlue;
    padding: 15px 20px;
    border:none;
    color: $white;
    width: 100%;
    font-family: $themeFont;
    font-size: 1.1rem;
    font-weight: 400;
}

.opt_form-submit{
    font-family: inherit;
    margin-top: 20px;
    padding: 8px 30px;
    border-radius: 60px;
    border:1px solid $themeTurquoiseLight;
    background-color: $themeTurquoise;
    color: $white;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.0133rem;
    @include transition(0.25s);
    cursor: pointer;
    height: 43px;
    width: 150px;

    @media (min-width: $laptopMin){
        font-size: 1rem
    }

    &:hover{
        background: $white;
        color: $themeTurquoise;
    }
}

.opt_form-error{
    color: #D12E2E;
    text-align: left;
    padding-top: 5px;
}