.opt_carousel {
    display: flex;
    
    .slick-slider {
        width: calc(100% - 40px);
        @media (min-width: $tabletMin){
            width: calc(100% - 50px);
        }
        
    }
    
    .slick-arrow {
        display: none!important;
    }
}

.opt_item{
    padding-right: 10px;
    img{
        width: 100%;
    }
}

.opt_arrow-next {
	margin: 0;
	width: 100%;
	background: #272D42;
    border: none;
}