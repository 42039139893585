html{
    scroll-behavior: smooth;
}

body{
    font-family: $themeFont;
    background-color: $themeBlue;
    font-size: 1rem;
    margin: 0;
}

p,
li,
a,
td,
th{
    font-size: 1rem;
    letter-spacing: 0.0133rem;
}

button{
	font-size: 0.8rem;
    letter-spacing: 0.04rem;
    margin: 15px 0;
}

a{
    text-decoration: none;
    font-family: $themeFont;
}

*{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

span{
    font-family: $themeFont;
    font-weight: 200;
}

h1{
    font-size: 2.6rem;
    margin: 15px 0;
    font-weight: 700;
    line-height: 47px;
    @media (min-width: $laptopMin){
        font-size: 4.6rem;
        line-height: 80px;
    }
    @media (min-width: $desktopMin){
        font-size: 5.8rem;
        line-height: 90px;
    }
}

h2{
    font-size: 2.2rem;
    margin: 15px 0 30px 0;
    font-weight: 600;
    line-height: 35px;
    @media (min-width: $tabletMax){
        font-size: 2.4rem;
    }
    @media (min-width: $tabletMin){
        margin: 15px 0 40px 0;
    }
    @media (min-width: $laptopMin){
        font-size: 2.8rem;
        line-height: 55px;
    }
    @media (min-width: $desktopMin){
        font-size: 3.6rem;
    }
}

h3{
    font-size: 1.1rem;
    margin: 15px 0;
    font-weight: 200;
    @media (min-width: $tabletMax){
        font-size: 1.2rem;
    }
    @media (min-width: $laptopMin){
        font-size: 1.3rem;
    }
    @media (min-width: $desktopMin){
        font-size: 1.3rem;
    }
}

h4{
    font-size: 1.4rem;
    margin: 15px 0;
    font-weight: 600;
    @media (min-width: $tabletMax){
        font-size: 1.6rem;
    }
    @media (min-width: $laptopMin){
        font-size: 2.8rem;
    }
    @media (min-width: $desktopMin){
        font-size: 3rem;
    }
}
h6{
    font-size: 0.8rem;
    margin: 15px 0;
    font-weight: 300;
    @media (min-width: $tabletMax){
        font-size: 0.9rem;
    }
}
p{
    margin: 15px 0;
    font-weight: 200;
    line-height: 25px;
}

figure{
    img{
        width: 100%;
    }
}